<template>
  <div class="container">
    <h2>Attendance Per Day</h2>
    <div class="d-flex justify-space-between mb-5">
      <form class="conFormSubmit conSearchForm mt-5">
        <v-row>
          <v-col cols="12" md="4">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="search.start_date"
                  label="From"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="date = parseDate(dateFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search.start_date"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="search.end_date"
                  label="To"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="date = parseDate(dateFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search.end_date"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col md="3" cols="12">
            <div class="conOfHeader text-right">
              <div class="btnAdd">
                <v-btn
                  class="btnAddPrimary"
                  @click="getListData()"
                  :disabled="isLoadingSearch"
                  :loading="isLoadingSearch"
                >
                  Search
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </form>
    </div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Attendance</th>
            <th class="text-left">Total Hours</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="index" v-for="(item, index) in listData">
            <td>{{ item.user_name }}</td>
            <td>
              <div class="mainDiv">
                <div
                  :key="index"
                  v-for="(attendance, index) in item.attendances_ber_day"
                  class="d-flex"
                >
                  <v-card class="ma-2" color="" height="110" width="150">
                    <div class="mainCardStyle">
                      <div class="day">
                        {{ attendance.attendance.date }}
                      </div>
                      <div class="cardStyle">
                        <div class="work">
                          <span class="graycolor"> IN</span>
                          {{ attendance.attendance.first_sign_in }}
                        </div>
                        <div class="leave">
                          <span class="graycolor"> Leave</span>
                          {{ attendance.attendance.last_sign_out }}
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>
            </td>
            <td>{{ item.total_hours }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    pagination: {
      current_page: 1,
    },
    searchpagination: {
      current_page: 1,
    },
    isLoadingSearch: false,

    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    secDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    menu1: false,
    menu2: false,
    search: {
      start_date: "",
      end_date: "",
      user_name: "",
    },
    listData: {},
  }),
  methods: {
    getListData() {
      this.listData = {};

      this.isLoading = true;
      apiServices
        .get(
          `attendances-ber-day?start=${this.search.start_date}&end=${this.search.end_date}`
        )
        .then((res) => {
          if (res) {
            this.listData = res.data;
            console.log("listData attendances-ber-day", this.listData);
            this.isLoading = false;
          } else this.isLoading = false;
        });
    },
  },
  created() {
    this.getListData();
  },
};
</script>
<style lang="postcss" scoped>
.mainDiv {
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  max-width: 650px;
}
.conOfCardClient {
  flex: 0 0 auto;
  margin-right: 10px;
}
.day {
  font-weight: 900;
  color: #000000;
}
.work,
.leave {
  color: #000000;
}
.graycolor {
  color: gray;
}
.mainCardStyle {
  padding: 10px;
}
.cardStyle {
  background-color: #a5acf8a3;
  padding: 10px;
  border-radius: 5px;
}
</style>
